import * as moment from "moment";
import { $filter } from "ngimport";
import { IReactionDisposer, reaction } from "mobx";
import { Transition } from "@uirouter/core";
import { NOTIFICATION_EVENTS } from "Constants/NotificationConstants";
import { UtilityFunctions, ObservableUtilities } from "Utilities";
import { NotificationService } from "Services";

function notificationsController($scope: any, notifications: any[]) {
  const vm = this;

  const reactionFactory = ObservableUtilities.createReactionFactory();

  vm.notifications = [];
  vm.unread = null;

  // pagination
  vm.pagination = {
    currentPage: 1,
    maxSize: 3,
    itemsPerPage: 10
  };

  // Default ordering for notification list
  vm.selectedFilter = 1;
  vm.reverse = true;

  const selectedFilterPredicateMap: any = {
    1: "NotificationID",
    2: "ReadWeb"
  };

  handleNotifications(notifications);

  /**
   * Notification list handler
   * Assigns list to VM, goes through each notification and checks
   * unread status and increases unread counter
   * @param  {array} notificationsList List of notifications
   */
  function handleNotifications(notificationsList: any[]): void {
    // Go through each notification and check unread status
    // and increase unread counter
    const unread = notificationsList.filter(
      (notification: UserNotification) => notification.ReadWeb === null
    ).length;

    vm.notifications = notificationsList;
    vm.unread = unread;
  }

  function getNewNotifications(): void {
    NotificationService.getNotificationsFromServer().then((data: any) => {
      handleNotifications(data);
    });

    // Order the notifications
    vm.order(selectedFilterPredicateMap[vm.selectedFilter], vm.reverse);
  }

  UtilityFunctions.largeSpinner("largeSpinner");

  vm.order = function order(predicate: string, reverse?: boolean): void {
    vm.notifications = $filter("orderBy")(vm.notifications, predicate, reverse);
  };

  vm.formatDate = function formatDate(date: Date, format: string): string {
    return moment(date, "YYYY-MM-DD").format(format);
  };

  // Order the notifications
  vm.order(selectedFilterPredicateMap[vm.selectedFilter], vm.reverse);

  reactionFactory.createReactions(
    reaction(
      () => NotificationService.getLocalNotifications(),
      getNewNotifications
    ),
    // Listen for when notification has been read
    reaction(
      () => NotificationService.getLocalNotifications(),
      (notifications: UserNotification[]) => {
        handleNotifications(notifications);
      },
      true
    ),
    reaction(
      () => NotificationService.getUnreadNotifications(),
      (unread: number) => {
        this.unreadNotifications = unread;
      }
    )
  );

  // TODO: Replace with component lifecycle $onDestroy when refactoring to components!
  $scope.$on("$destroy", () => {
    reactionFactory.disposeReactions();
  });
}

notificationsController.$inject = ["$scope", "notifications"];
export default notificationsController;
