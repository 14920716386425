import * as angular from "angular";
import { NotificationService } from "Services";
import NotificationsController from "Notifications/NotificationsController";
import NotificationsViewController from "Notifications/NotificationsViewController";
import * as notificationsTemplate from "Notifications/partials/notifications.html";
import * as notificationsViewTemplate from "Notifications/partials/view.html";
import { $uiRouter } from "Bootstrap/angular";
import { Transition } from "@uirouter/core";

export const MAIN_STATE: string = "notifications";

class Notifications {
  constructor() {
    const routes: any[] = [
      {
        name: `${MAIN_STATE}`,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        template: `${notificationsTemplate}`,
        controller: NotificationsController,
        controllerAs: "$ctrl",
        data: {
          roles: []
        },
        resolve: {
          notifications: () => {
            return NotificationService.getNotificationsFromServer();
          }
        }
      },
      {
        name: `${MAIN_STATE}.view`,
        url: "/:notificationID",
        template: `${notificationsViewTemplate}`,
        controller: NotificationsViewController,
        controllerAs: "$ctrl",
        data: {
          roles: []
        },
        resolve: {
          notification: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationService.getNotification(
                Number(toStateParams.notificationID)
              );
            }
          ],
          markRead: [
            "$transition$",
            ($transition$: Transition) => {
              const toStateParams = $transition$.params();
              return NotificationService.markNotificationAsRead(
                Number(toStateParams.notificationID)
              );
            }
          ]
        }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

angular.module("serviceWebApp").run(Notifications);
