import { Object } from "es6-shim";
import { UtilityFunctions } from "Utilities";

declare var $: any;

function notificationsViewController(notification: any) {
  let vm = this;

  // Merge the notification with the vm so that we don't have to set
  // each property manually
  vm = Object.assign(vm, notification);

  vm.formatDate = UtilityFunctions.formatDate;

  vm.showNotification = notification != null;

  // TODO: Remove jQuery
  $(".js-DisplayList").fadeTo(500, 1);
}

notificationsViewController.$inject = ["notification"];
export default notificationsViewController;
